// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.non-link {
    text-decoration: none;
    color: inherit;
}

.disabled-item {
    pointer-events: none;
    opacity: 0.4;
}

.import-button {
    display: block;
    margin: auto;
}

.national-results {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    column-gap: 50px;
}

.national-results p {
    font-size: 20px;
}

.national-results .simulator .img,
.national-results .strategy .img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: border-color 0.3s ease;
    width: 120px;
    height: 120px;
    margin: auto;
    margin-bottom: 20px;
}
.national-results .simulator .img img,
.national-results .strategy .img img {
    margin: auto;
    max-width: 80px;
    max-height: 80px;
}

.national-results .simulator .img:hover,
.national-results .strategy .img:hover {
    background-color: #efefefdd;
    border-color: darkgrey;
}

.missing-data {
    padding: 20px;
    font-size: 16px;
    background-color: #ffe9bc;
    margin: 20px;
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/style/national/portal.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;IACf,sCAAsC;IACtC,kCAAkC;IAClC,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,mBAAmB;AACvB;AACA;;IAEI,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;;IAEI,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".non-link {\n    text-decoration: none;\n    color: inherit;\n}\n\n.disabled-item {\n    pointer-events: none;\n    opacity: 0.4;\n}\n\n.import-button {\n    display: block;\n    margin: auto;\n}\n\n.national-results {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 0;\n    column-gap: 50px;\n}\n\n.national-results p {\n    font-size: 20px;\n}\n\n.national-results .simulator .img,\n.national-results .strategy .img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 20px;\n    background-color: white;\n    border: 1px solid lightgrey;\n    border-radius: 10px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    transition: border-color 0.3s ease;\n    width: 120px;\n    height: 120px;\n    margin: auto;\n    margin-bottom: 20px;\n}\n.national-results .simulator .img img,\n.national-results .strategy .img img {\n    margin: auto;\n    max-width: 80px;\n    max-height: 80px;\n}\n\n.national-results .simulator .img:hover,\n.national-results .strategy .img:hover {\n    background-color: #efefefdd;\n    border-color: darkgrey;\n}\n\n.missing-data {\n    padding: 20px;\n    font-size: 16px;\n    background-color: #ffe9bc;\n    margin: 20px;\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
