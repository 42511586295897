/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import { useEffect } from "react";
function _convertErrorToCleanMsg(error) {
  var correspondingMsg = {
    "Internal Server Error": "Le serveur a rencontré une erreur et ne peut gérer votre requête.",
    "Bad Request": "Le serveur ne peut gérer votre requête car elle est erronée."
  };
  return Object.keys(correspondingMsg).includes(error) ? correspondingMsg[error] : error;
}

/**
 * Function to make the link with the API of Terristory
 */
var Api = {
  // Request specific route from API
  callApi: function callApi(url, body) {
    var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "GET";
    var contentType = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "application/json";
    var abortController = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
    if (abortController === undefined) abortController = new AbortController();
    var headers = new Headers();
    var params = {
      body: body,
      method: method,
      credentials: "include",
      signal: abortController.signal
    };
    // Fetch is able to determine the correct header in the case of form (FormData) and file sending (even causes a bug if we try to force it)
    if (contentType !== "default") {
      headers.append("Content-Type", contentType);
      params["headers"] = headers;
    }
    var promise = fetch("".concat(url), params).then(function (response) {
      if (response.ok) {
        return response.json();
      }
      var errorMessage;
      return response.json().then(function (json) {
        // if we received a real json, we show the error message
        errorMessage = json.message;
      })["finally"](function () {
        var _errorMessage;
        // we convert the server msg error into something understandable
        var err = new Error((_errorMessage = errorMessage) !== null && _errorMessage !== void 0 ? _errorMessage : _convertErrorToCleanMsg(response.statusText));
        err.status = response.status;
        throw err;
      });
    });
    promise.abort = function () {
      abortController.abort();
    };
    return promise;
  },
  useLoadData: function useLoadData(url, onSuccess) {
    var _this = this;
    var onError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
    var updateDataLoaded = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
    var forceUpdate = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
    useEffect(function () {
      if (!url || !onSuccess) return;
      if (updateDataLoaded) updateDataLoaded(false);
      var promise = _this.callApi(url);
      promise.then(function (data) {
        if (updateDataLoaded) updateDataLoaded(true);
        onSuccess(data);
      })["catch"](function (error) {
        if (error.name === "AbortError") return;
        if (updateDataLoaded) updateDataLoaded(true);
        if (onError) onError(error);
      });
      return function () {
        return promise.abort();
      };
    }, [url, updateDataLoaded, onSuccess, onError, forceUpdate]);
  }
};
export default Api;