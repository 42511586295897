// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icons-blocks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.icon-block {
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    max-width: 250px;
    padding: 20px;
    margin: 10px;
    text-align: left;
}

.icon-block span.poi-legend {
    display: block;
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/style/admin/poi.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".icons-blocks {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n}\n\n.icon-block {\n    border: 1px solid #c0c0c0;\n    border-radius: 10px;\n    max-width: 250px;\n    padding: 20px;\n    margin: 10px;\n    text-align: left;\n}\n\n.icon-block span.poi-legend {\n    display: block;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
