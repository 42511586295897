/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

/**
 * Ce fichier définit dans l'objet `configData` les paramètres graphiques de la légende et de la carte (couleurs, taille échelle).
 *
 * Par exemple : le nombre de classe pour une représentation choroplèthe, les couleurs.
 */
var configData = {
  analysis_layer_index: 15,
  wms_layer_index: 3,
  anneeMin: 2011,
  confidential_layer_index: 14,
  zone_layer_index: 5,
  highlight_layer_index: 10,
  poi_layer_index: 20,
  edit_layer_index: 30,
  station_layer_index: 60,
  thresholdCircularDiagram: 5,
  data_epsg: "EPSG:4326",
  map_epsg: "EPSG:3857",
  minRadiusCircle: 5,
  maxRadiusCircle: 40,
  dashboardMaxRadiusCircle: 20,
  defaultColor: "#007f7b",
  strokeColorZone: "rgba(0, 0, 0, 0.5)",
  fillZone: "rgba(255, 255, 255, 0)",
  strokeColorHighlight: "rgba(0, 0, 0, 0.5)",
  fillHighlight: "rgba(255, 255, 255, 0.9)",
  strokeWidthZone: 0.5,
  strokeWidthZoneHighlight: 5,
  strokeColorEdit: "rgba(128, 0, 128, 0.9)",
  strokeWidthEdit: 2,
  fillColorEdit: "rgba(128, 0, 128, 0.4)",
  circleZeroColor: "#000000",
  circleZeroWidth: 2,
  circleNullWidth: 3,
  radiusEdit: 5,
  minWidthLine: 2,
  maxWidthLine: 25,
  poi_layer_color: [{
    statut: "En service",
    enProjet: "",
    fill: "rgba(0,156,221,0.8)",
    strike: "rgba(0,156,221,0.8)",
    width: 1,
    radius: 5
  },
  // TODO should be given by API
  {
    statut: "En projet",
    enProjet: "_en_projet",
    fill: "rgba(244,174,54,0.8)",
    strike: "rgba(244,174,54,1)",
    width: 1,
    radius: 5,
    pointillees: ["5", "5"]
  }],
  timeHideFooter: 5000,
  width_chart: 500,
  height_chart: 201,
  colors: ["#fecf44", "#f29400", "#1fa22e", "#0f5117", "#c0d362", "#91d4f2", "#006ab0", "#cfd1d2", "#616161", "#908958", "#933588", "#491b44", "#e32d61", "#c00000", "#a74d47", "#ff0000", "#1a171b"],
  nbClassChoropleth: 6,
  classAccessibiliteEmploi: ["Très mauvaise", "Assez mauvaise", "Plutôt mauvaise", "Plutôt bonne", "Assez bonne", "Très bonne"],
  modClassChoropleth: "quantiles",
  chartOffColor: "#efefef",
  chartFontColor: "rgb(40, 40, 40)",
  chartFontSize: 10,
  chartPadding: 6,
  chartMaxTitleLength: 40,
  planActionsColorEmploiRegion: "#009cdd",
  planActionsColorEmploiTerritoire: "#73d6ff",
  planActionsColorVAGRegion: "#e73836",
  planActionsColorVAGTerritoire: "#f29493",
  planActionsColorMix: "#ee7c0a",
  planActionsAnnees: [
  // to be deleted, if it is not used in any component other than PlanAction.js
  2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050],
  planActionDerniereAnnee: 2050,
  planActionsEnergieEco: "#93c127",
  planActionsEnergieProduite: "#93c127",
  planActionsFactureEnergetique: "#e71d73",
  planActionsEmissionGes: "#575756",
  planActionsRetombeesFiscalesCommune: "#d4910a",
  planActionsRetombeesFiscalesDepartement: "#f9d283",
  planActionsRetombeesFiscalesEPCI: "#f4b025",
  planActionsRetombeesFiscalesRegion: "#fbe2af",
  planActionsInvestissement: "#924bd9",
  nullZonesCirles: "#cccccc",
  notesChartZoneColor: "#93c127",
  notesChartMedianeColor: "#4f81bd",
  notesChartZoneColorSup: "#f4b025",
  analysisListColorItem: "#000",
  analysisListColorItemBg: "#eee",
  analysisListColorItemDisabled: "#aaa",
  analysisListColorPlanAction: "#f4ae36",
  analysisListColorterritorialsynthesis: "#95c232",
  suiviConsommationColor: "#c0504d",
  suiviProductionColor: "#5081bc",
  minFluxThreshold: 0,
  fluxThreshold: 500,
  maxFluxThreshold: 500,
  outsideFluxLocation: [883612.046976637, 5780473.82703817],
  countryFluxLocation: [{
    country: "AL",
    label: "Allemagne",
    location: [968610.022429753, 6472076.05896244]
  }, {
    country: "BE",
    label: "Belgique",
    location: [525886.754602013, 6577253.40988285]
  }, {
    country: "SU",
    label: "Suisse",
    location: [694185.005002132, 5842840.90035508]
  }, {
    country: "LU",
    label: "Luxembourg",
    location: [677537.818719801, 6410926.4363343]
  }],
  fluxThresholdId: -2,
  mapattributions: "",
  methodoPlanActionsPdf: "guide_plan_actions_transition_énergétique.pdf",
  methodoCreationTableauBord: "creation_tableau_de_bord.pdf",
  methodoSimulateurPdf: "guide_simulateur_mobilite.pdf",
  splashScreen: true,
  chartsDevicePixelRatio: 2
};
module.exports = configData;