// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SliderSingleSelectInput-module__sliderWrapper--wkrxZ {
    width: 100%;
    min-width: 175px;
    padding-bottom: 2em;
    padding-left: 1.3em;
    padding-right: 1.3em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Inputs/SliderSingleSelectInput.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":[".sliderWrapper {\n    width: 100%;\n    min-width: 175px;\n    padding-bottom: 2em;\n    padding-left: 1.3em;\n    padding-right: 1.3em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderWrapper": `SliderSingleSelectInput-module__sliderWrapper--wkrxZ`
};
export default ___CSS_LOADER_EXPORT___;
