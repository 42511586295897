// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

.TableContainer-module__TableContainer--nAtAW {
    vertical-align: middle;
}

.TableContainer-module__TableContainer--nAtAW th {
    font-size: 15px;
    padding: 5px;
}
.TableContainer-module__TableHeader--bIu6e {
    margin: 5px;
}

.TableContainer-module__Pagination--WBzpt {
    text-align: center;
    margin: auto;
    font-size: 16px;
}

.TableContainer-module__PageNumber--KwcOm {
    height: 100%;
    margin: 0px 10px;
    padding: 7px 0px;
}

.TableContainer-module__GoToPage--tueUy {
    height: 100%;
    margin: 0px 10px;
}

.TableContainer-module__TableContainer--nAtAW td.TableContainer-module__EmptyTable--bMyY7 {
    font-size: 15px;
    padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/style/TableContainer.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;EAkBE;;AAEF;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB","sourcesContent":["/*\n * TerriSTORY®\n *\n © Copyright 2022 AURA-EE\n *\n * This program is free software: you can redistribute it and/or modify\n * it under the terms of the GNU Affero General Public License as published by\n * the Free Software Foundation, either version 3 of the License, or\n * (at your option) any later version.\n *\n * This program is distributed in the hope that it will be useful,\n * but WITHOUT ANY WARRANTY; without even the implied warranty of\n * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the\n * GNU Affero General Public License for more details.\n *\n * A copy of the GNU Affero General Public License should be present along\n * with this program at the root of current repository. If not, see\n * http://www.gnu.org/licenses/.\n */\n\n.TableContainer {\n    vertical-align: middle;\n}\n\n.TableContainer th {\n    font-size: 15px;\n    padding: 5px;\n}\n.TableHeader {\n    margin: 5px;\n}\n\n.Pagination {\n    text-align: center;\n    margin: auto;\n    font-size: 16px;\n}\n\n.PageNumber {\n    height: 100%;\n    margin: 0px 10px;\n    padding: 7px 0px;\n}\n\n.GoToPage {\n    height: 100%;\n    margin: 0px 10px;\n}\n\n.TableContainer td.EmptyTable {\n    font-size: 15px;\n    padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableContainer": `TableContainer-module__TableContainer--nAtAW`,
	"TableHeader": `TableContainer-module__TableHeader--bIu6e`,
	"Pagination": `TableContainer-module__Pagination--WBzpt`,
	"PageNumber": `TableContainer-module__PageNumber--KwcOm`,
	"GoToPage": `TableContainer-module__GoToPage--tueUy`,
	"EmptyTable": `TableContainer-module__EmptyTable--bMyY7`
};
export default ___CSS_LOADER_EXPORT___;
