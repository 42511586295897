/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

export var get_complex_action_param = function get_complex_action_param(actions_params, action, engine) {
  var pourcent = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var res = Object.values(actions_params[action]).filter(function (x) {
    return x.id === engine;
  }).map(function (x) {
    return x.value !== undefined ? x.value : x["default"];
  })[0];
  // TODO : send unit with actions_params
  if (pourcent) {
    res /= 100;
  }
  return res;
};
export var get_inter_param = function get_inter_param(data_dict, data, category, engine) {
  return Object.values(data_dict).filter(function (x) {
    return x.data === data && x.category === category && x.engine === engine;
  }).map(function (x) {
    return x.value;
  })[0];
};
export var get_input_param = function get_input_param(data_dict, data, label) {
  return data_dict.filter(function (x) {
    return x["data"] === data && x["label"] === label;
  }).map(function (x) {
    return x.value;
  })[0];
};