// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminStyles-module__fullViewportWidth--CIjUt {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    overflow-x: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Admin/AdminStyles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".fullViewportWidth {\n    width: 100vw;\n    left: 50%;\n    transform: translateX(-50%);\n    position: relative;\n    overflow-x: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullViewportWidth": `AdminStyles-module__fullViewportWidth--CIjUt`
};
export default ___CSS_LOADER_EXPORT___;
