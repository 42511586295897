import _createForOfIteratorHelper from "/home/terristory/deploy/front/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
/*
 * TerriSTORY®
 *
 © Copyright 2022 AURA-EE
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * A copy of the GNU Affero General Public License should be present along
 * with this program at the root of current repository. If not, see
 * http://www.gnu.org/licenses/.
 */

import Style from "ol/style/Style.js";
import CircleStyle from "ol/style/Circle.js";
import Fill from "ol/style/Fill.js";
import Stroke from "ol/style/Stroke.js";
import Point from "ol/geom/Point.js";
import Icon from "ol/style/Icon.js";

// chroma.js est une petite bibliothèque pour toutes sortes de conversions de couleurs et d'échelles de couleurs
import chroma from "chroma-js";
import configData from "../settings_data.js";
import { slugify } from "../utils.js";

/*
 * Ce module gère le style des éléments cartographique du fichier OlMap.js. Il fait
 * appel aux paramètres définis dans :ref:`settings_data.js` dans ses fonctions. Ex :
 * couleur des cercles proportionnels.
 */

/**
 * Build color palette
 */
function getColorScale(data, size, colorStart, colorEnd, method, deuxiemeRepresentation, indicateurFlux, dataType) {
  var nbClasses = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : null;
  var nbClassChoropleth = nbClasses === null || !Number.isInteger(nbClasses) ? configData.nbClassChoropleth : nbClasses;
  var chromaData = [];
  var logaritmicForbidden = false;
  var val = "val";
  if (deuxiemeRepresentation && dataType !== "accessibilite_emploi") {
    val = "val_applats";
  } else if (dataType === "accessibilite_emploi") {
    // la couleur des circles est selon la valeur rapport: indice d’accessibilité
    val = "rapport";
  }
  var _iterator = _createForOfIteratorHelper(data),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var d = _step.value;
      chromaData.push(d[val]);
      if (d[val] != null && d[val] <= 0) {
        logaritmicForbidden = true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var colors = chroma.scale([colorStart, colorEnd]).mode("lch").colors(nbClassChoropleth + 1);
  var m = method;
  if (m === "logarithmic" && logaritmicForbidden) {
    m = configData.modClassChoropleth;
  }
  var breaks = chroma.limits(chromaData, m, nbClassChoropleth);
  var classesLegende = [];
  if (indicateurFlux) {
    var _iterator2 = _createForOfIteratorHelper(breaks),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _val = _step2.value;
        classesLegende.push(parseInt(_val, 10));
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
    breaks = classesLegende;
  } else if (dataType === "accessibilite_emploi") {
    for (var i in configData.classAccessibiliteEmploi) {
      classesLegende.push(configData.classAccessibiliteEmploi[i]);
    }
  }
  if (breaks.length < nbClassChoropleth + 1 || logaritmicForbidden) {
    // K-means (for example) does not garantee o have specified nb of classes, if so, we take the defaut one
    // Logaritmic does not allow negative or null values
    breaks = chroma.limits(chromaData, m, nbClassChoropleth);
  }
  var colorScale = chroma.scale(colors).classes(breaks);
  return {
    scale: colorScale,
    breaks: breaks,
    classesLegende: classesLegende
  };
}

/**
 * Style for analysis
 */
function styleAnalysis(feature, type, colorScale, meta, metaStyle, val, val_couleur) {
  var forceAverageRadius = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
  var forcedMinScale = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : undefined;
  if (type === "circle") {
    return styleAnalysisCircle(colorScale, meta, metaStyle, val, val_couleur, forceAverageRadius, forcedMinScale);
  }
  if (type === "flow") {
    return styleAnalysisLine(feature, colorScale, meta, metaStyle, val);
  }
  if (type === "choropleth" || type === "stars") {
    return styleAnalysisChoropleth(colorScale, meta, metaStyle, val);
  }
  if (type === "choropleth_cat") {
    return styleAnalysisChoroplethCat(meta, val);
  }
}

/**
 * Get normal color/stroke or NOT highlighted color
 */
function getColorAndStroke(val, colorScale, meta, useZeroValueColor) {
  var strokeWidth = 1;
  var color = colorScale(val);
  if (val === 0 && useZeroValueColor) {
    // instanciation de la couleur dans chroma
    color = chroma(configData.circleZeroColor);
  } else if (val === null) {
    color = chroma(configData.nullZonesCirles);
  }
  // assombrissement de la couleur avec la methode darken
  var strokeColor = color.darken();
  return {
    color: color.hex(),
    strokeColor: strokeColor.hex(),
    strokeWidth: strokeWidth
  };
}

/**
 * Style for analysis line
 */
function styleAnalysisLine(feature, colorScale, meta, metaStyle, val) {
  var cs = getColorAndStroke(val, colorScale, meta, true);
  // Calculate width
  var geometry = feature.getGeometry();
  var scaledWidth = getPropotionnalWidth(configData.minWidthLine, metaStyle.maxRadius, meta.min, meta.max, val);
  var newStyle = [new Style({
    stroke: new Stroke({
      color: cs.strokeColor,
      width: scaledWidth
    })
  })];
  if (geometry.flatCoordinates[0] === geometry.flatCoordinates[2] && geometry.flatCoordinates[1] === geometry.flatCoordinates[3]) {
    // Test geometry: if start = end, then do NOT display arrow
    newStyle = [new Style({
      stroke: new Stroke({
        color: cs.strokeColor,
        width: scaledWidth * 0.8
      })
    })];
  } else {
    geometry.forEachSegment(function (start, end) {
      var dx = end[0] - start[0];
      var dy = end[1] - start[1];
      var mid = [];
      mid.push((start[0] + end[0]) / 2);
      mid.push((start[1] + end[1]) / 2);
      var rotation = Math.atan2(dy, dx);
      // arrows
      newStyle.push(new Style({
        geometry: new Point(mid),
        image: new Icon({
          src: "img/arrow.png",
          anchor: [0.5, 0.5],
          rotateWithView: true,
          rotation: -rotation
        })
      }));
    });
  }
  return newStyle;
}
function getPropotionnalWidth(minWidth, maxWidth, min, max, val) {
  var rangeWidth = maxWidth - minWidth;
  var percent = (Math.abs(val) - min) / (max - min);
  var scaledWidth = percent * rangeWidth + minWidth;
  return scaledWidth;
}

/**
 * Style for analysis circle
 */
function styleAnalysisCircle(colorScale, meta, metaStyle, val, val_couleur, forceAverageRadius, forcedMinScale) {
  // Data will have a tag to tell if the data must be highlighted or not
  // (By default, all the data are displayed, but we have to highlight these in the selected zone)
  var cs = getColorAndStroke(val, colorScale, meta, true);
  if (val_couleur) {
    cs = getColorAndStroke(val_couleur, colorScale, meta, true);
  }

  // Set the default value to 0 to get the circle radius value except for forced and negative values (to have a more realistic visual)
  var minValue = 0;
  if (meta.min < 0) {
    minValue = meta.min;
  }

  // Calculate circle area for proportionality (with min / max)
  // TODO maxRadius should be renamed, because it is used also for maxWidth
  var scaledRadius = getPropotionalRadius(configData.minRadiusCircle, metaStyle.maxRadius, forcedMinScale !== undefined ? forcedMinScale : minValue, meta.max, val);
  if (forceAverageRadius === true) {
    scaledRadius = 0.75 * (metaStyle.maxRadius + configData.minRadiusCircle);
  }
  if (val === 0) {
    scaledRadius = configData.circleZeroWidth;
  } else if (val === null) {
    scaledRadius = configData.circleNullWidth;
  }
  var newStyle = new Style({
    image: new CircleStyle({
      radius: scaledRadius,
      fill: new Fill({
        color: cs.color
      }),
      stroke: new Stroke({
        color: cs.strokeColor,
        width: cs.strokeWidth
      })
    })
  });
  return newStyle;
}
function getPropotionalRadius(minRadius, maxRadius, min, max, val) {
  var min1 = min;
  // si on a des valeurs négatives, on décale tout pour remettre les valeurs
  // entre 0 et une valeur positive
  if (min < 0) {
    val -= min;
    max -= min;
    min = 0;
  }

  // si on un écart négatif et une valeur positive, problème
  if (max - min <= 0 && val !== null && val > 0) {
    return minRadius;
  }

  // We have to calculate the proportionality on the surface and not the radius
  var maxArea = Math.PI * maxRadius * maxRadius;
  var minArea = Math.PI * minRadius * minRadius;
  var rangeArea = maxArea - minArea;

  // on calcule le pourcentage
  var percent = (Math.abs(val) - min) / (max - min);
  if (min1 < 0) {
    // dans le cas de valeurs négatives initialement,
    // on utilise plutôt (max - val) que (val - min).
    percent = (max - val) / max;
  }
  var currentArea = Math.abs(percent) * rangeArea + minArea;
  var scaledRadius = Math.sqrt(currentArea / Math.PI);
  return scaledRadius;
}

/**
 * Style for analysis choropleth
 */
function styleAnalysisChoropleth(colorScale, meta, metaStyle, val) {
  var cs = getColorAndStroke(val, colorScale, meta, false);

  // Calculate circle area for proportionality (with min / max)
  var newStyle = new Style({
    fill: new Fill({
      color: cs.color
    }),
    stroke: new Stroke({
      color: cs.strokeColor,
      width: cs.strokeWidth
    })
  });
  return newStyle;
}

/**
 * Style for analysis choropleth by category
 */
function styleAnalysisChoroplethCat(meta, val) {
  var category = meta.carto_category;
  var modalite = category.find(function (_ref) {
    var modalite_id = _ref.modalite_id;
    return modalite_id === val;
  });
  if (modalite === undefined) {
    return styleUnavailableData();
  }
  return new Style({
    fill: new Fill({
      color: modalite.couleur
    }),
    stroke: new Stroke({
      color: configData.strokeColorZone,
      width: configData.strokeWidthZone
    })
  });
}

/**
 * pattern d'hachurage des territoires pour les données confidentielles
 * thanks http://jsfiddle.net/61b7szjn/
 */
function makeHatchingPattern() {
  var cnv = document.createElement("canvas");
  var ctx = cnv.getContext("2d");
  cnv.width = 6;
  cnv.height = 6;
  ctx.fillStyle = "rgb(231, 56, 54)";
  for (var i = 0; i < 6; ++i) {
    ctx.fillRect(i, i, 1, 1);
  }
  return ctx.createPattern(cnv, "repeat");
}

/**
 * Style for confidential territories
 */
function styleConfidentialZone() {
  var style = new Style({
    stroke: new Stroke({
      color: configData.strokeColorZone,
      width: configData.strokeWidthZone
    }),
    fill: new Fill({
      color: makeHatchingPattern()
    })
  });
  return style;
}

/**
 * Style for territories where no data is available
 */
function styleUnavailableData() {
  var style = new Style({
    stroke: new Stroke({
      color: configData.strokeColorZone,
      width: configData.strokeWidthZone
    }),
    fill: new Fill({
      color: "rgba(100, 100, 100, 0.3)"
    })
  });
  return style;
}

/**
 * Style for geographics zones
 */
function styleZone(feature) {
  return new Style({
    stroke: new Stroke({
      color: configData.strokeColorZone,
      width: configData.strokeWidthZone
    }),
    fill: new Fill({
      color: configData.fillZone
    })
  });
}

/**
 * Style for measuring stations
 */
function styleStationMesure(donnee) {
  return new Style({
    image: new Icon({
      anchor: [0.5, 1],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      src: "img/picto_" + donnee + ".png"
    })
  });
}

/**
 * Style for poi layers
 */
function stylePoiLayer(feature, region, layer, couleur, typeGeom, ancrageIcone) {
  var statut = feature.Statut;
  var enProjet = undefined;
  var type = "";
  if (feature.type) {
    type = "_" + slugify(feature.type);
  }
  var _iterator3 = _createForOfIteratorHelper(configData.poi_layer_color),
    _step3;
  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var s = _step3.value;
      if (statut) {
        if (s.statut.substring(0, 10) === statut.substring(0, 10)) {
          // On teste juste le début de la chaine (projet/service)
          enProjet = s.enProjet;
          break;
        }
      } else {
        enProjet = configData.poi_layer_color[0].enProjet;
      }
    }
    // Center the icon by default
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }
  var ancrage = [0.5, 0.5];
  if (ancrageIcone === "milieu_bas") {
    ancrage = [0.5, 1];
  }
  var newStyle = new Style({
    image: new Icon({
      anchor: ancrage,
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
      src: "svg/" + region + "/" + layer + enProjet + ".svg",
      color: couleur
    })
  });
  if (feature.type) {
    newStyle = new Style({
      image: new Icon({
        anchor: ancrage,
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: "svg/" + region + "/" + layer + enProjet + type + ".svg"
      })
    });
  }
  if (typeGeom !== "Point") {
    return;
  }
  return newStyle;
}

/**
 * Style for pixels layers
 */
function stylePixelsLayer(couleur) {
  var stroke = new Stroke({
    color: couleur,
    width: 1
  });
  var fill = new Fill({
    color: couleur
  });
  var newStyle = new Style({
    fill: fill,
    stroke: stroke
  });
  return newStyle;
}

/**
 * Style for highlight
 */
function styleHighlight() {
  return [new Style({
    stroke: new Stroke({
      color: configData.strokeColorHighlight,
      width: configData.strokeWidthZoneHighlight
    })
  })];
}

/**
 * Style for POI edition
 */
function styleEdit() {
  return [new Style({
    image: new CircleStyle({
      radius: configData.radiusEdit,
      fill: new Fill({
        color: configData.fillColorEdit
      }),
      stroke: new Stroke({
        color: configData.strokeColorEdit,
        width: configData.strokeWidthEdit
      })
    })
  })];
}

/**
 * Style object gathering all possible styles available for map styling.
 */
var style = {
  getPropotionalRadius: getPropotionalRadius,
  getColorScale: getColorScale,
  styleAnalysis: styleAnalysis,
  styleZone: styleZone,
  styleHighlight: styleHighlight,
  styleEdit: styleEdit,
  stylePixelsLayer: stylePixelsLayer,
  stylePoiLayer: stylePoiLayer,
  styleUnavailableData: styleUnavailableData,
  styleConfidentialZone: styleConfidentialZone,
  styleStationMesure: styleStationMesure,
  styleAnalysisChoroplethCat: styleAnalysisChoroplethCat
};
export default style;