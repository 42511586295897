// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Animations */

.fade-in-section {
    opacity: 0;
    transform: translateY(6vh);
    transition: opacity 0.6s ease-out, transform 1.5s ease-out;
    will-change: opacity;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
}
`, "",{"version":3,"sources":["webpack://./src/style/fadeInSection.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,UAAU;IACV,0BAA0B;IAC1B,0DAA0D;IAC1D,oBAAoB;AACxB;AACA;IACI,UAAU;IACV,eAAe;AACnB","sourcesContent":["/* Animations */\n\n.fade-in-section {\n    opacity: 0;\n    transform: translateY(6vh);\n    transition: opacity 0.6s ease-out, transform 1.5s ease-out;\n    will-change: opacity;\n}\n.fade-in-section.is-visible {\n    opacity: 1;\n    transform: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
