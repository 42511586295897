// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-status {
    text-align: center;
    color: var(--col-danger);
    margin-top: 10px;
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/style/national/login.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,wBAAwB;IACxB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".error-status {\n    text-align: center;\n    color: var(--col-danger);\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
