// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.return-button {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
    width: fit-content;
}

.submit-container {
    display: flex;
    justify-content: space-evenly;
    margin: 24px auto;
}

.header-other-dashboard {
    padding: 30px;
}

.home-page-national {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
    padding: 16px;
}

.home-page-national .content {
    max-width: 600px;
    font-size: 16px;
    line-height: normal;
    text-align: justify;
}
`, "",{"version":3,"sources":["webpack://./src/style/national/welcomedashboard.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".return-button {\n    display: block;\n    margin-top: 10px;\n    margin-left: 10px;\n    width: fit-content;\n}\n\n.submit-container {\n    display: flex;\n    justify-content: space-evenly;\n    margin: 24px auto;\n}\n\n.header-other-dashboard {\n    padding: 30px;\n}\n\n.home-page-national {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 32px;\n    padding: 16px;\n}\n\n.home-page-national .content {\n    max-width: 600px;\n    font-size: 16px;\n    line-height: normal;\n    text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
